<template>
    <div class="sticky-top toolbox-toolbar">
        <BInputGroup
            v-if="operations.length || filter.length"
            class="mb-2"
        >
            <template #prepend>
                <BInputGroupText
                    class="clickable"
                    @click="$refs.operationFilter.focus()"
                >
                    <FontAwesomeIcon icon="filter" />
                </BInputGroupText>
            </template>

            <BInput
                ref="operationFilter"
                v-model="filter"
                placeholder="filter operations..."
                autocomplete="off"
                data-cy="filterActions"
            />
        </BInputGroup>

        <Draggable
            v-model="operations"
            :group="{name: 'operations', pull: 'clone', put: false}"
            :sort="false"
        >
            <MToolboxCard
                v-for="operation in operations"
                :key="operation.id"
                :heading="operation.function || ''"
                :description="operation.description"
            />
        </Draggable>

        <div
            v-if="!operations.length && filter.length"
            class="text-muted w-100 text-center"
        >
            No operations match what you've filtered.
        </div>

        <div
            v-else-if="!operations.length"
            class="text-muted w-100 text-center"
        >
            No operations found.
        </div>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
    import {mapGetters} from 'vuex';

    import MToolboxCard from './MToolboxCard.vue';

    export default {
        name: 'MToolboxOperations',
        components: {
            Draggable,
            MToolboxCard,
        },
        data() {
            return {
                filter: '',
            };
        },
        computed: {
            operations: {
                get() {
                    return this.filter.length ? this.actions.filter(t => RegExp(this.filter, 'gi').test(`${t.function} ${t.description}`)) : this.actions;
                },
                set() {
                    return false;
                },
            },
            ...mapGetters('builder', [
                'actions',
            ]),
        },
    };
</script>

<style lang="sass">
    .toolbox-card
        + .toolbox-card
            margin-top: 0.5rem

    .toolbox
        .toolbox-toolbar
            overflow-y: auto
            padding: 0.25rem
            margin-bottom: 0.25rem
</style>
