<template>
    <div class="builder-toolbar container-fluid">
        <div class="row p-2">
            <div class="col-12 col-lg-auto px-0 mr-2 mb-2 mb-lg-0">
                <BButton
                    v-if="canManage"
                    v-shortkey="{windows: ['ctrl', 'shift', 'l'], mac: ['meta', 'shift', 'l']}"
                    variant="primary"
                    class="w-100"
                    data-cy="loadDataMap"
                    :disabled="saving"
                    @shortkey="$router.push({name: 'admin.datamaps.load'})"
                    @click="$router.push({name: 'admin.datamaps.load'})"
                >
                    <FontAwesomeIcon
                        icon="cloud-upload"
                        class="mr-1"
                    />
                    {{ loadedWording }}
                </BButton>
            </div>
            <div class="col-12 col-lg-auto px-0 mb-2 mb-lg-0">
                <BButton
                    v-if="canManage"
                    v-shortkey="{windows: ['ctrl', 'shift', 'r'], mac: ['meta', 'shift', 'r']}"
                    variant="primary"
                    class="w-100"
                    data-cy="previewDataMap"
                    :disabled="saving"
                    @shortkey="$emit('run-preview')"
                    @click="$emit('run-preview')"
                >
                    <FontAwesomeIcon
                        icon="play"
                        class="mr-1"
                    />
                    Preview Data Map
                </BButton>
            </div>

            <div :class="['col-12', 'col-lg-auto', 'ml-lg-auto', 'px-0', 'mb-2', 'mb-lg-0', {'mr-lg-2': readOnly || isPublished(version)}]">
                <BButton
                    id="backToDataMaps"
                    class="w-100"
                    data-cy="backToDataMaps"
                    variant="primary"
                    :disabled="saving"
                    @click="returnToDataMaps"
                >
                    <FontAwesomeIcon
                        icon="stream"
                        class="mr-1"
                    />
                    Back to All Data Maps
                </BButton>

                <PopConfirm
                    v-if="hasChanges"
                    placement="left"
                    target="backToDataMaps"
                    title="There are unsaved changes, do you wish to continue without saving?"
                    @confirmed="$router.push({name: 'admin.datamaps.list'})"
                />
            </div>

            <div
                v-if="!readOnly && !isPublished(version)"
                class="col-12 col-lg-auto px-0 px-lg-2 mb-2 mb-lg-0"
                data-cy="saveDataMap"
            >
                <BButton
                    v-shortkey="{windows: ['ctrl', 's'], mac: ['meta', 's']}"
                    class="w-100"
                    :variant="saving ? 'warning' : 'primary'"
                    :disabled="saving"
                    @shortkey="$emit('save')"
                    @click="$emit('save')"
                >
                    <div v-if="!saving">
                        <FontAwesomeIcon
                            icon="save"
                            class="mr-1"
                        />
                        Save
                    </div>

                    <div v-else>
                        <FontAwesomeIcon
                            class="disabled"
                            far
                            icon="spinner"
                            pulse
                        />
                    </div>
                </BButton>
            </div>

            <div class="col-12 col-lg-3 px-0">
                <BInputGroup>
                    <template #prepend>
                        <BInputGroupText
                            class="clickable"
                            @click="$refs.operationFilter.focus()"
                        >
                            <FontAwesomeIcon icon="filter" />
                        </BInputGroupText>
                    </template>

                    <BInput
                        ref="operationFilter"
                        v-model="filter"
                        placeholder="filter operations by title..."
                        data-cy="filterOperations"
                    />
                </BInputGroup>
            </div>
        </div>
    </div>
</template>

<script>
    import PopConfirm from '@imt/vue-kit-car/src/components/PopConfirm.vue';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import versioningMixin from '@imt/vue-versioning/src/mixins/versioning';
    import {mapMutations, mapState} from 'vuex';

    import authMixin from '../../mixins/auth';

    export default {
        name: 'MBuilderToolbar',
        components: {
            PopConfirm,
        },
        mixins: [
            authMixin,
            toastsMixin,
            versioningMixin
        ],
        props: {
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                operations: [],
                filter: '',
                loadedObject: false,
            };
        },
        computed: {
            loadedWording() {
                return localStorage.getItem(`loadedObject${this.$route.params.id}`) ? 'Change Object to Map' : 'Load Object to Map';
            },
            ...mapState('builder', [
                'version',
                'saving',
                'hasChanges',
            ]),
        },
        watch: {
            filter(newValue) {
                this.SET_FILTER(newValue);
            },
        },
        methods: {
            returnToDataMaps() {
                if (!this.hasChanges) {
                    this.$router.push({name: 'admin.datamaps.list'});
                }
            },
            ...mapMutations('builder', [
                'SET_FILTER',
            ]),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .builder-toolbar
        background-color: var(--kc-gray-400)
        position: sticky
        top: 0
        z-index: 1010

        .clickable
            &:hover
                cursor: pointer

        .input-group-text
            &.clickable
                &:hover
                    background-color: var(--kc-gray-200)

    .configure-variables-input
        min-height: 30vh

    .mode
        &.dark
            .builder-toolbar
                background-color: var(--kc-blue-800)

                .input-group-text
                    &.clickable
                        &:hover
                            background-color: var(--kc-gray-400)
</style>
