<template>
    <div>
        <div :class="['workspace-operation', {unsaved: !isSaved}]">
            <div class="bar-top" />

            <div class="workspace-operation-header">
                <div class="workspace-operation-title">
                    Operation {{ step }}: {{ operation.function }}
                </div>

                <div
                    :id="`step${step}InfoButton`"
                    class="workspace-operation-actions"
                >
                    <FontAwesomeIcon
                        v-b-toggle="`descriptionCollapse${step}`"
                        icon="info-circle"
                        size="lg"
                        class="fa kc-help-wording action info mr-2"
                    />
                </div>

                <div
                    v-if="!readOnly"
                    :id="`step${step}OptionsButton`"
                    :data-cy="`deleteOperation${step}`"
                    class="workspace-operation-actions"
                >
                    <FontAwesomeIcon
                        icon="trash-alt"
                        size="lg"
                        class="action delete"
                    />

                    <BTooltip
                        :target="`step${step}OptionsButton`"
                        triggers="hover"
                        placement="left"
                        :title="`Delete Operation ${step}`"
                    />

                    <KCPopConfirm
                        :target="`step${step}OptionsButton`"
                        placement="left"
                        @confirmed="deleteOperation"
                    />
                </div>
            </div>

            <BCollapse
                :id="`descriptionCollapse${step}`"
                class="workspace-operation-description px-4"
                visible
            >
                <p>
                    {{ getDescription }}
                </p>
            </BCollapse>

            <div class="workspace-operation-content">
                <div class="row w-100">
                    <h5 class="col-12">
                        Arguments
                    </h5>

                    <div
                        v-if="!Object.keys(operation.arguments)"
                        class="col-12 text-muted"
                        data-cy="noArgumentsMessage"
                    >
                        <em>Operation has no arguments.</em>
                    </div>


                    <template v-for="(value, argument, index) in operation.arguments">
                        <ValidationProvider
                            v-if="argument !== 'obj'"
                            :id="`operation${operation.id}Argument${index}`"
                            :ref="`${argument}-argument`"
                            v-slot="{errors}"
                            :key="argument"
                            :vid="`argumentContainer${index}`"
                            name="argument"
                            class="col-12 row"
                            :rules="isOptional(operation, argument)"
                            tag="div"
                        >
                            <h6 class="col-12">
                                {{ capitalWords(argument.replace(/_/g, ' ')) }}
                                <template v-if="operation.helpWording">
                                    <FontAwesomeIcon
                                        :id="`argumentHelpWording${argument}`"
                                        icon="info-circle"
                                        class="fa kc-help-wording"
                                        data-cy="argumentHelpWordingIcon"
                                    />

                                    <BPopover
                                        custom-class="kc-help-wording"
                                        :target="`argumentHelpWording${argument}`"
                                        :title="
                                            capitalWords(
                                                argument.replace(/_/g, ' ')
                                            )
                                        "
                                        triggers="hover click"
                                        data-cy="argumentHelpWordingPopover"
                                    >
                                        {{ operation.helpWording[argument] }}
                                    </BPopover>
                                </template>
                            </h6>

                            <BFormGroup
                                :id="`argumentContainer${index}`"
                                class="col-12 col-lg-12"
                                :state="errors.length ? false : null"
                            >
                                <KCSelectionBox
                                    v-if="typeof value === 'boolean'"
                                    :id="`argumentSelectionBox${index}`"
                                    v-model="booleanSelectionBoxResults"
                                    :pre-selected="[value]"
                                    :allow-empty="false"
                                    data-cy="booleanSelectionBoxOptions"
                                    :button-options="booleanSelectionBoxOptions"
                                    @selection-box-input="updateBooleanValue(argument, $event[0])"
                                />
                                <input
                                    v-else
                                    :id="`argumentTextInput${index}`"
                                    type="text"
                                    :class="['form-control', {'is-invalid': errors.length}]"
                                    :placeholder="`specify the ${argument.includes('path') ? 'path to the value...' : 'value to be added...'}`"
                                    :disabled="readOnly"
                                    :data-cy="`${argument}`"
                                    :value="value"
                                    @input="$emit('updated', {key: `arguments[${argument}]`, value: $event.target.value})"
                                >

                                <template #invalid-feedback>
                                    {{ errors.join(', ') }}
                                </template>
                            </BFormGroup>
                        </ValidationProvider>
                    </template>
                </div>

                <slot />
            </div>
        </div>

        <div class="tail" />
    </div>
</template>

<script>
    import KCPopConfirm from '@imt/vue-kit-car/src/components/PopConfirm.vue';
    import KCSelectionBox from '@imt/vue-kit-car/src/components/SelectionBox.vue';
    import filterMixin from '@imt/vue-toolbox/src/mixins/filters';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import {mapGetters, mapMutations, mapState} from 'vuex';

    export default {
        name: 'MWorkspaceOperation',
        components: {
            KCPopConfirm,
            KCSelectionBox
        },
        mixins: [filterMixin, toastsMixin],
        props: {
            isSaved: {
                type: Boolean,
                required: true,
            },
            operation: {
                type: Object,
                required: true,
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
            step: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                booleanSelectionBoxOptions: [
                    {
                        'text': 'Yes',
                        'value': true
                    },
                    {
                        'text': 'No',
                        'value': false
                    }
                ],
                booleanSelectionBoxResults: []
            };
        },
        computed: {
            getDescription() {
                return this.actions.find((operation) => {
                    return operation.function === this.operation.function;
                })?.description;
            },
            ...mapGetters('builder', ['actions']),
            ...mapState('builder', ['availableOperations', 'version']),
        },
        created() {
            this.loadArgumentValue();
        },
        methods: {
            loadArgumentValue() {
                Object.keys(this.operation.arguments).forEach(key => {
                    if (!key.includes('obj') && !key.includes('path')) {
                        this.booleanSelectionBoxResults.push(this.operation.arguments[key]);
                    }
                });
            },
            deleteOperation() {
                this.SET_DATA({
                    type: 'saving',
                    data: true,
                });

                if (this.operation.id) {
                    try {
                        this.$store.dispatch(
                            'builder/deleteOperation',
                            this.operation.id
                        );

                        this.REMOVE_OPERATION(this.operation.id);
                        this.success(
                            'Successfully deleted operation.',
                            'Delete Operation'
                        );
                    } catch (e) {
                        this.error(
                            'Error deleting operation, please try again.',
                            'Delete Operation'
                        );
                    }
                } else {
                    this.REMOVE_OPERATION_AT_INDEX(this.step - 1);
                }

                this.SET_DATA({
                    type: 'saving',
                    data: false,
                });
            },
            updateBooleanValue(argument, value) {
                this.$emit('updated', {key: `arguments[${argument}]`, value});
            },
            isOptional(operation, argument) {
                let optionalFields = this.availableOperations.optional_fields;
                if (Object.keys(optionalFields).includes(operation.function) && optionalFields[operation.function].includes(argument)) {
                    return 'valid-path';
                } else {
                    return 'required|valid-path';
                }
            },
            ...mapMutations('builder', [
                'REMOVE_OPERATION',
                'REMOVE_OPERATION_AT_INDEX',
                'SET_DATA',
            ]),
        },
    };
</script>

<style lang="sass" scoped>
    .workspace-operation
        background-color: var(--kc-gray-100)
        border-radius: var(--card-border-radius)
        display: flex
        flex-direction: column

        .bar-top
            height: 0.25rem
            background: var(--kc-blue-500)
            width: 100%
            border-radius: var(--card-border-radius) var(--card-border-radius) 0 0
            margin: 0

        &.unsaved
            .bar-top
                background: var(--kc-yellow-500)

        .workspace-operation-header
            display: flex
            flex-direction: row
            font-size: 1.2rem
            padding: 0.75rem
            margin-bottom: 0.25rem

            .workspace-operation-title
                flex-grow: 1

            .workspace-operation-actions
                flex-grow: 0
                margin-left: auto
                align-self: center

        .action
            &:hover
                cursor: pointer

            &.delete
                color: var(--kc-red-500)

            &.options
                color: var(--kc-blue-500)

        .workspace-operation-content
            display: flex
            flex-direction: column
            align-items: flex-start
            padding: 0 1rem 1rem 1rem

            .fa
                &.animated-transform
                    transition: transform 250ms

            h5
                font-weight: bold

    .bg-alternate
        background-color: var(--kc-gray-300) !important

    .tail
        border-left: 0.25rem dashed var(--kc-gray-200)
        height: 2rem
        width: 0
        margin: -0.5rem auto 0 auto

    .mode
        &.dark
            .custom-control-input:checked
                ~ .custom-control-label
                    &::before
                        background-color: var(--kc-blue-500)
                        border-color: var(--kc-blue-500)

            .workspace-operation
                background-color: var(--kc-blue-900)

                .bar-top
                    background: var(--kc-blue-600)

                &.unsaved
                    .bar-top
                        background: var(--warning)

                .action
                    color: var(--kc-blue-400)

                    &.delete
                        color: var(--kc-red-500)

                        &:hover
                            color: var(--kc-red-600)

                    &:hover
                        color: var(--kc-blue-600)
                        cursor: pointer

            .bg-alternate
                background-color: var(--kc-blue-800) !important
</style>
