<template>
    <div class="toolbox">
        <div class="toolbox-heading">
            <FontAwesomeIcon
                class="ml-2 mr-2"
                icon="tasks-alt"
            />
            Operations
        </div>

        <MToolboxOperations />
    </div>
</template>

<script>
    import filtersMixin from '@imt/vue-toolbox/src/mixins/filters';

    import MToolboxOperations from './MToolboxOperations.vue';

    export default {
        name: 'MToolbox',
        components: {
            MToolboxOperations,
        },
        mixins: [filtersMixin]
    };
</script>

<style
    lang="sass"
    scoped
>
    .toolbox
        width: 20%
        height: 100%
        display: flex
        flex-direction: column
        background-color: var(--kc-gray-300)

        @media (max-width: 900px)
            width: 50%

        @media (max-width: 500px)
            display: none

        .toolbox-heading
            align-items: center
            background-color: var(--kc-gray-400)
            border-bottom: 1px solid var(--kc-gray-400)
            display: flex
            flex-shrink: 0
            height: 58px

    .mode.dark
        .toolbox
            background-color: var(--kc-blue-800)

            .toolbox-heading
                background-color: var(--kc-blue-800)
                border-bottom-color: var(--kc-blue-700)
</style>
