<template>
    <div class="col-12 col-lg-5 data-map-preview">
        <h4 class="font-weight-bold">
            Data Map Result
        </h4>

        <AceEditor
            ref="editor"
            data-cy="previewContent"
            mode="json"
            :options="{ readOnly: true, wrap: true }"
            theme="chrome"
            :value="DataMapResultPreview"
        />
    </div>
</template>

<script>
    export default {
        name: 'MDataMapPreview',
        props: {
            resultingObject: {
                type: Object,
                default() {
                    return {};
                }
            },
        },
        computed: {
            DataMapResultPreview() {
                return Object.keys(this.resultingObject).length ? JSON.stringify(this.resultingObject, null, 4) : '';
            }
        },
    };
</script>

<style lang="sass" scoped>
    .data-map-preview
        border-left: solid var(--kc-blue-800)

        .aceeditor
            height: calc(100vh - 72px)
</style>
